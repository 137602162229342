<template>
  <div class="company-list">
    <div class="main-info">
      <div class="main-info__user">
        <multiselect
          v-model="userId"
          :options="arrayUserIds"
          :searchable="false"
          :show-labels="false"
          track-by="name"
          label="name"
          :allow-empty="false"
          placeholder="User"
          @select="getItems"
        ></multiselect>
      </div>
      <div class="main-info__filters">
        <div
          class="icon__filter"
        >
          <img
            src="@/assets/images/global.svg"
            alt="icon"
            @click="showLangModal = !showLangModal"
          />
        </div>
      </div>
    </div>
    <div v-if="showLangModal" class="modal-lang">
      <div class="modal-content">
        <span class="close" @click="showLangModal = !showLangModal">&times;</span>
        <h2>{{ $t('choose_lang') }}</h2>
        <button @click="changeLanguage('en')">English</button>
        <button @click="changeLanguage('uk')">Українська</button>
        <button @click="changeLanguage('ru')">русский</button>
      </div>
    </div>
    <div class="input-block">
      <input
        v-if="items.length > 0"
        id="input"
        type="text"
        v-model="inputVal"
        :placeholder="$t('search')"
      />
    </div>
    <ul v-if="items.length > 0" class="list">
      <li
        class="list__item"
        :class="{'paused': !item.is_active, 'anim': item.isAnim }"
        v-for="item of paginatedData"
        :key="item.id"
      >
        <div class="list__back">
          <div
            v-if="!item.load"
            class="loader__block"
          >
            <img
              class="loader"
              src="@/assets/images/loader-white.gif"
            />
          </div>
          <div v-if="item.load" class="list__back--top">
            <div @click="changeAnim(item)">
              <img src="@/assets/images/back.svg" alt="icon" />
            </div>
            <multiselect
              v-if="item.stats"
              v-model="item.choosedGeoStats"
              :options="item.geosStats"
              :searchable="false"
              :show-labels="false"
              :allow-empty="false"
              @select="searchValue(item)"
            ></multiselect>
            <multiselect
              v-if="item.stats"
              v-model="item.choosePeriod"
              :options="periodOpt"
              :searchable="false"
              :show-labels="false"
              :allow-empty="false"
              track-by="name"
              label="name"
              @select="changePeriod(item)"
            ></multiselect>
          </div>

          <div
            v-if="item.stats"
            class="list__back--push-stat"
          >
            <div class="title">{{ $t('push_title') }}</div>
            <p><span>{{ $t('players_total') }}</span>
              <span class="value">
                {{ item.push_stat.app_total_players }}
              </span>
            </p>
            <p><span>{{ $t('players_active') }}</span>
              <span class="value">
                {{ item.push_stat.app_active_players }}
              </span>
            </p>
            <p><span>{{ $t('players_total2active') }}</span>
              <span class="value">
              {{ item.push_stat.app_active2total_players }}%
              </span>
            </p>
          </div>
          <div
            v-if="item.stats"
            class="list__back--values"
          >
            <div class="title">{{ $t('traffic_title') }}</div>
            <p>
              <span>{{ $t('campaign_unique_clicks') }}</span>
              <span class="value">{{ result.campaign_unique_clicks }}</span>
            </p>
            <p style="margin-top: 25px">
              <span>{{ $t('installs') }}</span>
              <span class="value">{{ result.installs }}</span>
            </p>
            <p>
              <span>{{ $t('installs2unique') }}</span>
              <span class="value">{{ result.installs2unique }}%</span>
            </p>
            <p style="margin-top: 25px">
              <span>{{ $t('regs') }}</span>
              <span class="value">{{ result.leads }}</span>
            </p>
            <p>
              <span>{{ $t('regs2unique') }}</span>
              <span class="value">{{ result.leads2unique }}%</span>
            </p>
            <p style="margin-bottom: 20px">
              <span>{{ $t('regs2install') }}</span>
              <span class="value">{{ result.leads2installs }}%</span>
            </p>
            <p>
              <span>{{ $t('sales') }}</span>
              <span class="value">{{ result.sales }}</span>
            </p>
            <p>
              <span>{{ $t('sales2unique') }}</span>
              <span class="value">{{ result.sales2unique }}%</span>
            </p>
            <p>
              <span>{{ $t('sales2installs') }}</span>
              <span class="value">{{ result.sales2installs }}%</span>
            </p>
            <p>
              <span>{{ $t('sales2regs') }}</span>
              <span class="value">{{ result.sales2leads }}%</span>
            </p>

          </div>
        </div>
        <div class="list__info">
          <div>ID: {{ item.id }}</div>
          <span>|</span>
          <div>{{ $t('type') }}: {{ item.type }}</div>
          <div
            class="status"
            :class="{'active': item.is_active}"
          >
            {{ item.status }}
          </div>
        </div>
        <div class="list__details">
          <multiselect
            v-model="item.choisedDomain"
            :class="{not_active: !item.is_active}"
            :options="item.domains"
            :searchable="false"
            :show-labels="false"
            track-by="domain"
            label="domain"
            :allow-empty="false"
            placeholder="domain"
          ></multiselect>

        </div>
        <div class="icon-block">
          <img
            v-if="item.is_active"
            @click="addDomain(item)"
            src="@/assets/images/add.svg"
            alt="icon"
          />
          <img
            v-if="item.is_active"
            @click="copyDomain(item)"
            class="copy"
            src="@/assets/images/link.svg"
            alt="icon"
          />
          <img
            v-if="item.is_active"
            @click="previewDomain(item)"
            src="@/assets/images/preview.svg"
            alt="icon"
          />
          <img
            v-if="item.is_active"
            @click="changeAnim(item)"
            src="@/assets/images/stats.svg"
            alt="icon"
          />
        </div>
        <div v-if="!more" class="btn__group">
          <button
            v-if="
            // url.get('is_dev') &&
            item.is_active"
            @click="openMore(item)"
          >
            {{ $t('edit') }}
          </button>
          <button
            v-if="!item.is_active"
            @click="openModalRemove(item)"
          >
            {{ $t('start') }}
          </button>
<!--          <button-->
<!--            v-if="!item.is_active"-->
<!--            @click="openModalDelete(item)"-->
<!--          >-->
<!--            Delete-->
<!--          </button>-->
          <button
            v-else
            @click="openModalRemove(item)"
          >
            {{ $t('stop') }}
          </button>
        </div>
      </li>
    </ul>
    <div v-else style="width: 100%; height: 90vh;display: flex;align-items: center;justify-content: center">
      {{ $t('no_links') }}
    </div>
    <modal-more
      v-if="isModalMoreOpen"
      :item="itemForMore"
      @close="isModalMoreOpen = !isModalMoreOpen"
    />
    <modal-preview
      v-if="isModalPreviewOpen"
      :url-to-preview="srcToPreview"
      @close="isModalPreviewOpen = !isModalPreviewOpen"
      @edit="showItem"
    />
    <modal-remove
      v-if="isModalRemoveOpen"
      :item="itemClick"
      @close="isModalRemoveOpen = !isModalRemoveOpen"
      @remove="removeItem"
    />

    <modal-copy
      v-if="isModalCopy"
      @close="isModalCopy = !isModalCopy"
    />
    <modal-delete
      v-if="isModalDelete"
      :item="itemClick"
      @close="isModalDelete = !isModalDelete"
      @delete="deleteItem"
    />
    <modal-add-domain
      :item="itemClick"
      v-if="isModalAdd"
      @close="isModalAdd = !isModalAdd"
    />
    <div class="pagination" v-if="items.length > 3">
      <div class="pagination__item">
        <button
          @click="prevPage"
        >
          {{ $t('prev') }}
        </button>
      </div>
      <div class="pagination__item">
        <button
          @click="nextPage"
        >
          {{ $t('next') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ModalMore from "@/components/ModalMore.vue";
import ModalRemove from "@/components/ModalRemove.vue";
import Multiselect from "vue-multiselect";
import ModalPreview from "@/components/ModalPreview.vue";
import ModalCopy from "@/components/ModalCopy.vue";
import ModalAddDomain from "@/components/ModalAdd.vue";
import ModalDelete from "@/components/ModalDelete.vue";
import { cookiesAction } from "@/cookies-action";

export default {
  name: 'ListBlock',
  components: {
    ModalAddDomain,
    ModalCopy,
    ModalPreview, Multiselect, ModalRemove, ModalMore, ModalDelete,
  },
  data() {
    return {
      showLangModal: false,
      selectedLanguage: this.$i18n.locale,
      anim: false,
      pageNumber: 0,
      inputVal: "",
      size: 3,
      items: [],
      isModalCopy: false,
      isModalAdd: false,
      isModalEditOpen: false,
      isModalPreviewOpen: false,
      isModalRemoveOpen: false,
      isModalMoreOpen: false,
      isModalDelete: false,
      isOpenFilter: false,
      itemForMore: [],
      itemClick: [],
      choisedDomain: [],
      result:[],
      more: false,
      choosedGeoStats: [],
      static: '?pixel={pixel}&sub1={sub1}&sub2={sub2}&sub3={sub3}&sub4={sub4}&sub5={sub5}&sub6={sub6}',
      staticPreview: '?sub_id_30=open_pwa',
      srcToPreview: '',
      userId: '',
      itemStats: [],
      url: new URLSearchParams(window.location.search),
      arrayUserIds: [],
      periodOpt: [
        {
          name: this.$t('today'),
          value: 'today'
        },
        {
          name: this.$t('yesterday'),
          value: 'yesterday'
        },
        {
          name: this.$t('last_7_days'),
          value: '7_days_ago'
        },
        {
          name: this.$t('this_week'),
          value: 'last_monday'
        },
        {
          name: this.$t('last_30_days'),
          value: '1_month_ago'
        },
        {
          name: this.$t('last_month'),
          value: 'previous_month'
        },
        {
          name: this.$t('this_month'),
          value: 'first_day_of_this_month'
        },
        {
          name: this.$t('this_year'),
          value: 'first_day_of_this_year'
        },
        {
          name: this.$t('all_time'),
          value: 'all_time'
        },
      ],
      objectUserIds: JSON.parse(
        atob(
          new URLSearchParams(window.location.search).get('userId')
        )
      )
    };
  },
  computed: {
    listItem() {
      const searchLower = this.extractDomain(this.inputVal.toLowerCase());
      return this.items.filter(item => {
        const campaignUrlMatches = item.campaign_url.toLowerCase().includes(searchLower);
        const domainMatches = item.domains.some(domain => domain.domain.toLowerCase().includes(searchLower));
        this.pageNumber = 0;
        return campaignUrlMatches || domainMatches;
      });
    },
    paginatedData(){
      const start = this.pageNumber * this.size;
      const end = start + this.size;

      return this.listItem.slice(start, end);
    }
  },
  methods: {
    changeLanguage(ln) {
      cookiesAction.setCookie('ln', ln);
      this.$i18n.locale = ln;
      this.showLangModal = false;
    },
    extractDomain(url) {
      try {
        const hostname = new URL(url).hostname;
        return hostname;
      } catch (e) {
        return url;
      }
    },
    searchValue(item) {
      console.log('this res', item.choosedGeoStats)

      this.result = item.stats.traffic_stat[item.choosedGeoStats];
      console.log('fff', this.result)
    },
    changePeriod(item) {
      item.load = false;
      const vm = this;
      this.axios.get(`https://fambanli.xyz/links/${item.id}/stat?interval=${item.choosePeriod.value}`)
        .then((response) => {
          item.stats = response.data;
          item.geosStats = Object.keys(item.stats.traffic_stat);
          item.push_stat = response.data.push_stat;
          item.load = true;
          item.geosStats.forEach((elem) => {
            if (elem.toUpperCase().includes('TOTAL')) {
              item.choosedGeoStats = elem;
            }
          })
          vm.result = item.stats.traffic_stat['Total']
          console.log('this items', Object.keys(item.stats.traffic_stat))
        })
        .catch((error) => {
          console.error('error', error);
        })
    },
    changeAnim(item) {
      const vm = this;
      item.isAnim = !item.isAnim;
      item.load = false;
      if (!item.choosePeriod) {
        item.choosePeriod = {
          name: "Today",
          value: "today"
        };
      }
      this.axios.get(`https://fambanli.xyz/links/${item.id}/stat?interval=${item.choosePeriod.value}`)
        .then((response) => {
          item.stats = response.data;
          item.geosStats = Object.keys(item.stats.traffic_stat);
          item.push_stat = response.data.push_stat;
          item.geosStats.forEach((elem) => {
            if (elem.toUpperCase().includes('TOTAL')) {
              item.choosedGeoStats = elem;
            }
          })
          vm.result = item.stats.traffic_stat['Total']
          item.load = true;
          console.log('this items', Object.keys(item.stats.traffic_stat))
        })
        .catch((error) => {
          console.error('error', error);
        })
    },
    createUserArray() {
      let first = [];
      first.push({
        'name': Object.keys(this.objectUserIds)[0],
        'value': Object.values(this.objectUserIds)[0],
      });

      let temp = [];
      for (let i = 1; i < Object.keys(this.objectUserIds).length; i++) {
        temp.push({
          'name': Object.keys(this.objectUserIds)[i],
          'value': Object.values(this.objectUserIds)[i],
        });
      }

      temp.sort(function(a,b){
        return a.name.localeCompare(b.name);
      })

      this.arrayUserIds = first.concat(temp);
      this.arrayUserIds.concat(temp);
      this.setUserId(this.arrayUserIds);
    },
    setUserId(item) {
      this.userId = item[0];
      this.getItems();
    },
    getItems() {
      const vm = this;

      this.axios.get(`https://fambanli.xyz/users/${this.userId.value}/links`)
        .then((response) => {
          vm.items = response.data;
          vm.items.filter((item) => {
            item.choisedDomain = item.domains[0];
            item.status = item.is_active ? this.$t('active') : this.$t('paused');
            item.isAnim = false;
          });
          console.log('this items', vm.items)
        })
        .catch((error) => {
          console.error('error', error);
          if (error.response.status === 401) {
            vm.items = [];
          }
        })
    },
    prevPage() {
      if (this.pageNumber !== 0) {
        this.pageNumber--;
      }
    },
    nextPage() {
      if (this.pageNumber !== Math.ceil(this.listItem.length / this.size) - 1) {
        this.pageNumber++;
      }
    },
    openModalRemove(item) {
      this.itemClick = item;
      this.isModalRemoveOpen = !this.isModalRemoveOpen;
    },
    openModalDelete(item) {
      this.itemClick = item;
      this.isModalDelete = !this.isModalDelete;
    },
    showItem(data) {
      this.isModalEditOpen = !this.isModalEditOpen;
      window.Telegram.WebApp.sendData(JSON.stringify(data.item));
    },
    removeItem(data) {
      this.isModalRemoveOpen = !this.isModalRemoveOpen
      window.Telegram.WebApp.sendData(data.removeItem);
    },
    deleteItem(data) {
      this.isModalDelete = !this.isModalDelete
      window.Telegram.WebApp.sendData(data.deleteItem);
    },
    addDomain(item) {
      this.itemClick = item;
      this.isModalAdd = true;
    },
    copyDomain(item) {
      const vm = this;
      try {
        navigator.clipboard.writeText(`https://${item.choisedDomain.domain}/${item.choisedDomain.alias}${vm.static}&domain=https://${item.choisedDomain.domain}`);
        this.isModalCopy = true;
        console.log('Content copied to clipboard');
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    },
    previewDomain(item) {
      this.srcToPreview = `https://${item.domains[0].domain}/${item.domains[0].alias}${this.staticPreview}`;
      this.isModalPreviewOpen = !this.isModalPreviewOpen;
    },
    openMore(item) {
      this.itemForMore = item;
      this.isModalMoreOpen = true;
    }
  },
  beforeMount() {
    let urlParams = new URLSearchParams(window.location.search).get('is_dev');
    if (urlParams) {
      this.isDev = true;
    }
    this.createUserArray();
  }
}
</script>


<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style lang="scss">
.multiselect {
  width: auto;

  .list__details & {
    width: 330px;

    &__tags {
      width: 100%;
    }
  }

  &.not_active {
    width: 320px;

    .multiselect__tags {
      width: 320px;
    }

    .multiselect__content-wrapper {
      width: 320px;
    }
  }

  &__tags {
    background: #242424;
  }

  &__select {
    width: unset;
    height: unset;
    right: 10px;
    top: 10px;
  }

  &__content-wrapper {
    top: 37px;
    background: #242424;
    z-index: 222222;
    min-height: max-content;
    max-height: 300px!important;
  }

  &__option--highlight {
    background: #ff2a00;
  }

  &__single {
    background: transparent;
    color: white;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__element {
    color: #fff;

    span {
      font-size: 12px;
      text-overflow: ellipsis;
      display: block;
      overflow: hidden;
    }
  }
}
.main-info {
  display: flex;
  margin: 10px 10px -10px 10px;
  align-items: center;
  justify-content: space-between;

  .multiselect,
  .multiselect__tags,
  .multiselect__content-wrapper {
    width: 300px;
    min-width: 157px;
  }

  .multiselect__content-wrapper {
    max-height: 200px!important;
  }

  .multiselect__tags {
    background: transparent;
  }

  .multiselect__select {
    right: 10px;
    top: 15px;
    width: 10px;
    height: 0;
    &::before {
      top: -5px!important;
      left: -5px;
      display: block;
      height: 0;
    }
  }
}

.icon {
  &__filter {
    width: 40px;
    position: relative;
    top: 2px;
    left: 0px;
    cursor: pointer;
  }
}

.modal-lang {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1111;

  .modal-content {
    background-color: #161616;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;


    h2 {
      margin-bottom: 20px;
    }

    button {
      width: 100%;
      height: 40px;
      background: #242424;
      color: white;
      border-radius: 10px;
      border: none;
    }
  }

  .close {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 20px;
    cursor: pointer;
  }

}
</style>
