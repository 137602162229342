import { createI18n } from 'vue-i18n';
import {cookiesAction} from "@/cookies-action";

const messages = {
    en: {
        search: 'Search by selected domain',
        push_title: 'Push',
        players_total: 'Players total',
        players_active: 'Players active',
        players_total2active: 'Players total to active',

        traffic_title: 'Traffic',
        campaign_unique_clicks: 'Campaign unique clicks',

        installs: 'Installs',
        installs2unique: 'Installs to unique',

        regs: 'Regs',
        regs2unique: 'Regs to unique',
        regs2installs: 'Regs to installs',

        sales: 'Sales',
        sales2unique: 'Sales to unique',
        sales2installs: 'Sales to installs',
        sales2regs: 'Sales to regs',

        today: 'Today',
        yesterday: 'Yesterday',
        last_7_days: 'Last 7 days',
        this_week: 'This week',
        last_30_days: 'Last 30 days',
        last_month: 'Last month',
        this_month: 'This month',
        this_year: 'This year',
        all_time: 'All time',

        total: 'Total',

        status_active: 'Active',
        status_paused: 'Paused',

        start: 'Start',
        stop: 'Stop',
        delete: 'Delete',
        edit: 'Edit',

        type: 'Type',

        add_domain: 'Are you sure to add domain?',
        delete_link: 'Are you sure to delete this link?',
        copy_link: 'Link was copied',
        preview: 'Preview link',

        yes: 'Yes',
        cancel: 'Cancel',
        ok: 'Ok',
        close: 'Close',
        update: 'Update',
        back: 'Back',
        next: 'Next',
        prev: 'Previous',

        more_about_id: 'More about ID',
        edit_design: 'Edit design',
        edit_campaign_url: 'Edit campaign URL',

        edit_campaign_url_id: 'EDIT CAMPAIGN URL ID',
        new_campaign_url: 'New campaign URL',

        edit_clo: 'Edit clo on',

        choose_GEO: 'Choose GEO',
        input_secure_key: 'Input secure key',
        reference_of_whit_page: 'Reference of white page',

        example: 'Example',
        no_links: 'No links were found',
        choose_lang: 'Choose language'
    },
    ru: {
        search: 'Поиск по выбранному домену',
        edit_clo: 'Редактировать клоаку',
        push_title: 'Пуш',
        players_total: 'Общее количество игроков',
        players_active: 'Активные игроки',
        players_total2active: 'Общее количество игроков к активным',
        traffic_title: 'Трафик',
        campaign_unique_clicks: 'Уникальные клики кампании',
        installs: 'Установки',
        installs2unique: 'Установки к уникальным',
        regs: 'Регистрации',
        regs2unique: 'Регистрации к уникальным',
        regs2installs: 'Регистрации к установкам',
        sales: 'Продажи',
        sales2unique: 'Продажи к уникальным',
        sales2installs: 'Продажи к установкам',
        sales2regs: 'Продажи к регистрациям',
        today: 'Сегодня',
        yesterday: 'Вчера',
        last_7_days: 'Последние 7 дней',
        this_week: 'На этой неделе',
        last_30_days: 'Последние 30 дней',
        last_month: 'Прошлый месяц',
        this_month: 'В этом месяце',
        this_year: 'В этом году',
        all_time: 'За всё время',
        total: 'Всего',
        status_active: 'Активный',
        status_paused: 'Приостановлен',
        start: 'Запустить',
        stop: 'Остановить',
        delete: 'Удалить',
        type: 'Тип',
        add_domain: 'Вы уверены, что хотите добавить домен?',
        delete_link: 'Вы уверены что хотите удалить это?',

        copy_link: 'Ссылка скопирована',
        preview: 'Предварительный просмотр ссылки',
        yes: 'Да',
        cancel: 'Отмена',
        ok: 'ОК',
        close: 'Закрыть',
        update: 'Обновить',
        back: 'Назад',
        next: 'Далее',
        prev: 'Предыдущий',
        more_about_id: 'Подробнее об ID',
        edit_design: 'Редактировать дизайн',
        edit_campaign_url: 'Редактировать URL кампании',
        edit_campaign_url_id: 'РЕДАКТИРОВАТЬ ID URL КАМПАНИИ',
        new_campaign_url: 'Новый URL кампании',
        choose_GEO: 'Выберите GEO',
        input_secure_key: 'Введите защитный ключ',
        reference_of_whit_page: 'Ссылка на белую страницу',
        example: 'Пример',
        no_links: 'Ссылки не найдены',
        choose_lang: 'Выберите язык',
        edit: 'Редактировать'
    },
    uk: {
        search: 'Пошук по вибраному домену',
        delete_link: 'Ви впевнені що хочете видалити це?',
        edit_clo: 'Редагувати клоаку',
        push_title: 'Пуш',
        players_total: 'Загальна кількість гравців',
        players_active: 'Активні гравці',
        players_total2active: 'Загальна кількість гравців до активних',
        traffic_title: 'Трафік',
        campaign_unique_clicks: 'Унікальні кліки кампанії',
        installs: 'Інстали',
        installs2unique: 'Інстали до уніків',
        regs: 'Реєстрації',
        regs2unique: 'Реєстрації до уніків',
        regs2installs: 'Реєстрації до інсталів',
        sales: 'Продажі',
        sales2unique: 'Продажі до уніків',
        sales2installs: 'Продажі до інсталів',
        sales2regs: 'Продажі до реєстрацій',
        today: 'Сьогодні',
        yesterday: 'Вчора',
        last_7_days: 'Останні 7 днів',
        this_week: 'Цього тижня',
        last_30_days: 'Останні 30 днів',
        last_month: 'Минулого місяця',
        this_month: 'Цього місяця',
        this_year: 'Цього року',
        all_time: 'За весь час',
        total: 'Всього',
        status_active: 'Активний',
        status_paused: 'Призупинений',
        start: 'Запустити',
        stop: 'Зупинити',
        delete: 'Видалити',
        type: 'Тип',
        add_domain: 'Ви впевнені, що хочете додати домен?',
        copy_link: 'Посилання було скопійовано',
        preview: 'Попередній перегляд посилання',
        yes: 'Так',
        cancel: 'Скасувати',
        ok: 'ОК',
        close: 'Закрити',
        update: 'Оновити',
        back: 'Назад',
        next: 'Далі',
        prev: 'Попередній',
        more_about_id: 'Докладніше про ID',
        edit_design: 'Редагувати дизайн',
        edit_campaign_url: 'Редагувати URL кампанії',
        edit_campaign_url_id: 'РЕДАГУВАТИ ID URL КАМПАНІЇ',
        new_campaign_url: 'Новий URL кампанії',
        choose_GEO: 'Виберіть GEO',
        input_secure_key: 'Введіть захисний ключ',
        reference_of_whit_page: 'Посилання на вайт пейдж',
        example: 'Приклад',
        no_links: 'Посилання не знайдені',
        choose_lang: 'Оберіть мову',
        edit: 'Редагувати'
    }
};

let urlLang = new URLSearchParams(window.location.search).get('ln');

if (urlLang && !cookiesAction.getCookie('ln')) {
    console.log('11');
    cookiesAction.setCookie('ln', urlLang)
}

if (!cookiesAction.getCookie('ln') && (urlLang !== 'en' && urlLang !== 'uk' && urlLang !== 'ru')) {
    cookiesAction.setCookie('ln', 'uk');
}

let lang = cookiesAction.getCookie('ln');
console.log('lang', lang)

const i18n = createI18n({
    locale: lang,
    messages,
});

export default i18n;
